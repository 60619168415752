import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const swiperTop = new Swiper(".partners-top", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 11,
  loop: true,
  allowTouchMove: false,
  speed: 4000,
  autoplay: {
    delay: 0,
  },
  breakpoints: {
    992: {
      spaceBetween: 20,
    },
  },
});

const swiperBottom = new Swiper(".partners-bottom", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 11,
  loop: true,
  allowTouchMove: false,
  speed: 6000,
  autoplay: {
    delay: 0,
    reverseDirection: true,
  },
  breakpoints: {
    992: {
      spaceBetween: 20,
    },
  },
});

const speakersSlider = new Swiper(".speakers-slider", {
  autoHeight: true,
  slidesPerView: 2,
  spaceBetween: 11,

  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 4,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 7,
      spaceBetween: 20,
    },
  },
});
const panelSlider = new Swiper(".panel-slider", {
  autoHeight: true,
  slidesPerView: 2,
  spaceBetween: 11,

  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

const documentationSwiper = new Swiper(".documentation-swiper", {
  autoHeight: true,
  slidesPerView: 1,
  spaceBetween: 11,
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 11,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  },
});

const ourPartnersSwiper = new Swiper(".our-partners-swiper", {
  autoHeight: true,
  slidesPerView: "auto",
  spaceBetween: 20,
  loop: true,
  loopAdditionalSlides: 2, // Додаткові слайди для коректної роботи циклу
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
