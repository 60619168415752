function formSubmit() {
  const popupOpenButtons = document.querySelectorAll(".popup-open");

  popupOpenButtons.forEach((button) => {
    button.addEventListener("click", function () {
      const formType = this.getAttribute("data-type");

      const forms = document.querySelectorAll(".telegramForm");
      forms.forEach((form) => {
        form.setAttribute("data-type", formType);
      });
    });
  });

  const forms = document.querySelectorAll(".telegramForm");
  const formSuccess = document.querySelectorAll(".form-success");

  forms.forEach((form) => {
    form.addEventListener("submit", function (e) {
      e.preventDefault();

      const formData = new FormData(e.target);
      const name = formData.get("name");
      const email = formData.get("email");
      const phone = formData.get("phone");
      const formType = form.getAttribute("data-type");

      let extraMessage = "";

      if (formType === "member") {
        extraMessage = "Стати учасником";
      } else if (formType === "partner") {
        extraMessage = "Стати партнером";
      }

      const botToken = "7536664755:AAHHO34rxhQJEEygrM4V8cxgp5fR4rqaMMo";
      const chatId = "-1002232957015";

      // Відправка повідомлення в Telegram
      fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: `Ім'я: ${name}\nЕлектронна пошта: ${email}\nНомер телефону: ${phone}${
            extraMessage && formType ? `\n${extraMessage}` : ""
          }`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            formSuccess.forEach((item) => (item.style.display = "flex"));
            e.target.reset();
            forms.forEach((item) => item.removeAttribute("data-type"));
          } else {
            alert("Сталася помилка під час відправки повідомлення.");
          }
        })
        .catch((error) => {
          console.error("Помилка:", error);
          alert("Сталася помилка під час відправки повідомлення.");
        });
    });
  });
}

function closeForm() {
  const buttons = document.querySelectorAll(".close-button");
  const formSuccess = document.querySelectorAll(".form-success");
  const backdrop = document.querySelectorAll(".backdrop");

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      formSuccess.forEach((item) => (item.style.display = "none"));
      backdrop.forEach((item) => item.classList.remove("show"));
    });
  });
}

document.addEventListener("DOMContentLoaded", formSubmit);
document.addEventListener("DOMContentLoaded", closeForm);
