document.addEventListener("DOMContentLoaded", () => {
  const bankBtns = document.querySelectorAll(".menu-item-19");
  const backdrop = document.querySelector(".backdrop.bank-popup");
  bankBtns.forEach((btn) => {
    btn.classList.add("popup-open", "bank-popup");
    btn.addEventListener("click", () => {
      if (backdrop) {
        backdrop.classList.add("show");
      }
    });
  });

  if (backdrop) {
    backdrop.addEventListener("click", (e) => {
      if (e.target === e.currentTarget) {
        backdrop.classList.remove("show");
      }
    });
  }
});
