document.querySelectorAll(".popup-open").forEach((e) => {
  e.addEventListener("click", () => {
    let t = document.querySelector(`.backdrop.${e.classList[1]}`),
      c = () => {
        t?.classList.remove("show"),
          t?.querySelector(".close-button")?.removeEventListener("click", c),
          window.removeEventListener("click", s),
          document.documentElement.classList.add("interaction-disabled"),
          setTimeout(() => {
            document.documentElement.classList.remove("interaction-disabled");
          }, parseInt(getComputedStyle(t)?.getPropertyValue("--_show-speed")) || 500);
      },
      s = (e) => {
        e.target == t && c();
      };
    t &&
      (t.classList.add("show"),
      t.querySelector(".close-button")?.removeEventListener("click", c),
      window.removeEventListener("click", s),
      setTimeout(() => {
        t.querySelector(".close-button")?.addEventListener("click", c),
          window.addEventListener("click", s);
      }, parseInt(getComputedStyle(t)?.getPropertyValue("--_show-speed")) || 500));
  });
});
