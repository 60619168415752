function hideMenu() {
  const links = document.querySelectorAll(".main-nav a");
  const backdrop = document.querySelectorAll(".backdrop");
  links.forEach((item) => {
    item.addEventListener("click", () => {
      backdrop.forEach((item) => item.classList.remove("show"));
    });
  });
}

document.addEventListener("DOMContentLoaded", hideMenu);
